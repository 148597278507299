/* global  */ // tUrl等のグローバル変数を宣言する
// import Swiper from 'swiper' // Swiper
// import PerfectScrollbar from 'perfect-scrollbar' // 独自スクロールバー
// position:styckyのポリフィル
// Stickyfill.add(document.querySelectorAll('.sticky'))
/* global homeUrl*/

jQuery(function ($) {
  $(window).scroll(function () {
    //スクロール時に一度だけ行う処理
    //600px以上の場合
    if (window.matchMedia('(min-width: 600px)').matches) {
      $('.header').each(function () {
        var scrollhv = $(window).scrollTop() //スクロールした量を計測して変数に代入
        var hh = 150
        if (hh < scrollhv) {
          //スクロールした分＞（下から出す要素の高さ-ウィンドウの高さ）＋位置の調整
          $(this).addClass('active') //超過したときに該当のクラスにstopを追加
        } else {
          $(this).removeClass('active') //超過したときに該当のクラスにstopを追加
        }
      })
    } else {
      //600px以下の場合
      $('.header').each(function () {
        var scrollhv = $(window).scrollTop() //スクロールした量を計測して変数に代入
        var hh = 20
        if (hh < scrollhv) {
          //スクロールした分＞（下から出す要素の高さ-ウィンドウの高さ）＋位置の調整
          $(this).addClass('active') //超過したときに該当のクラスにstopを追加
        } else {
          $(this).removeClass('active') //超過したときに該当のクラスにstopを追加
        }
      })
    }
  })
  $('.mobile-btn').click(function () {
    $(this).toggleClass('active')

    $(this).parents('.header').siblings('.sp-nav').toggleClass('active')
    $(this).parents('.header').siblings('.bg-over-lay').toggleClass('active')
  })
  $('.bg-over-lay').click(function () {
    $(this).removeClass('active')
    $(this).siblings('.sp-nav').removeClass('active')
    // $(this).siblings('.sp-nav').removeClass('active')
  })

  // ######## ########  ##     ## ##    ## ##    ##  #######
  //    ##    ##     ## ##     ## ###   ## ##   ##  ##     ##
  //    ##    ##     ## ##     ## ####  ## ##  ##   ##     ##
  //    ##    ########  ##     ## ## ## ## #####     #######
  //    ##    ##   ##   ##     ## ##  #### ##  ##   ##     ##
  //    ##    ##    ##  ##     ## ##   ### ##   ##  ##     ##
  //    ##    ##     ##  #######  ##    ## ##    ##  #######
  function trunk8() {
    $('.js-t8.line1').trunk8({
      // 指定要素に行数制限をかける
      lines: 1,
      fill: '...'
    })
    $('.js-t8.line2').trunk8({
      // 指定要素に行数制限をかける
      lines: 2,
      fill: '...'
    })
  }
  trunk8()
  //trunk8処理関数を実行
  // URLにhogehogeが含まれていたら実行
  if (document.URL.match('maehara')) {
    $('.newsList').removeClass('minamiVer')
    $('.newsList').addClass('maeharaVer')
    $('.flex-pager').removeClass('minamiVV')
    $('.flex-pager').addClass('maeharaVV')
  } else if (document.URL.match('minami')) {
    $('.newsList').removeClass('maeharaVer')
    $('.newsList').addClass('minamiVer')
    $('.flex-pager').removeClass('maeharaVV')
    $('.flex-pager').addClass('minamiVV')
  }
  // ########    ###    ########  ########         #### ##    ##
  // ##         ## ##   ##     ## ##                ##  ###   ##
  // ##        ##   ##  ##     ## ##                ##  ####  ##
  // ######   ##     ## ##     ## ######   #######  ##  ## ## ##
  // ##       ######### ##     ## ##                ##  ##  ####
  // ##       ##     ## ##     ## ##                ##  ##   ###
  // ##       ##     ## ########  ########         #### ##    ##
  $(window).scroll(function () {
    //スクロール時に一度だけ行う処理
    //600px以上の場合
    if (window.matchMedia('(min-width: 600px)').matches) {
      $('.fade-in').each(function () {
        var position = $(this).offset().top
        var scroll = $(window).scrollTop() //スクロールした量を計測して変数に代入
        var windowHeight = $(window).height() //ウィンドウの高さを取得して代入する
        if (scroll > position - windowHeight + 150) {
          //スクロールした分＞（下から出す要素の高さ-ウィンドウの高さ）＋位置の調整
          $(this).addClass('active') //超過したときに該当のクラスにstopを追加
        }
      })
    } else {
      //600px以下の場合
      $('.fade-in').each(function () {
        var position = $(this).offset().top
        var scroll = $(window).scrollTop() //スクロールした量を計測して変数に代入
        var windowHeight = $(window).height() //ウィンドウの高さを取得して代入する
        if (scroll > position - windowHeight + 50) {
          //スクロールした分＞（下から出す要素の高さ-ウィンドウの高さ）＋位置の調整
          $(this).addClass('active') //超過したときに該当のクラスにstopを追加
        }
      })
    }
  })
  var ref = document.referrer
  if (ref.indexOf('access') !== -1) {
    $('.fade-in').addClass('none') //超過したときに該当のクラスにstopを追加
  } else if (ref.indexOf('contact') !== -1) {
    $('.fade-in').addClass('none') //超過したときに該当のクラスにstopを追加
  } else if (ref.indexOf('contact-thanks') !== -1) {
    $('.fade-in').addClass('none') //超過したときに該当のクラスにstopを追加
  } else if (ref.indexOf('contact-confirm') !== -1) {
    $('.fade-in').addClass('none') //超過したときに該当のクラスにstopを追加
  } else if (ref.indexOf('greeting') !== -1) {
    $('.fade-in').addClass('none') //超過したときに該当のクラスにstopを追加
  } else if (ref.indexOf('disclosure') !== -1) {
    $('.fade-in').addClass('none') //超過したときに該当のクラスにstopを追加
  } else if (ref.indexOf('recruit') !== -1) {
    $('.fade-in').addClass('none') //超過したときに該当のクラスにstopを追加
  } else if (ref.indexOf('about') !== -1) {
    $('.fade-in').addClass('none') //超過したときに該当のクラスにstopを追加
  } else if (ref.indexOf('minami') !== -1) {
    $('.fade-in').addClass('none') //超過したときに該当のクラスにstopを追加
  } else if (ref.indexOf('information') !== -1) {
    $('.fade-in').addClass('none') //超過したときに該当のクラスにstopを追加
  } else if (ref.indexOf('maehara') !== -1) {
    $('.fade-in').addClass('none') //超過したときに該当のクラスにstopを追加
  }
})
